<template>
  <div>
    
    <v-card elevation="0">
      <validation-observer ref="observer">
        <v-form class="mt-4">
          <v-container class="pt-5">
            <v-row>
              <v-col md="2" sm="12" cols="12">
                <validation-provider v-slot="{ errors }" vid="placa">
                  <v-text-field
                    v-model="veiculoNovo.placa"
                    outlined
                    dense
                    :readonly="readonly"
                    hide-details="auto"
                    :disabled="$store.state.layout.loading"
                    label="Placa"
                    :error-messages="errors"
                    class="placa"
                    @keyup="uppercase"
                  />
                </validation-provider>
              </v-col>
              <v-col md="6" sm="12" cols="12">
                <validation-provider v-slot="{ errors }" vid="descricao">
                  <v-text-field
                    v-model="veiculoNovo.descricao"
                    outlined
                    dense
                    :readonly="readonly"
                    hide-details="auto"
                    :disabled="$store.state.layout.loading"
                    label="Descrição"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col md="2" sm="12" cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  vid="veiculoNovo.tipo_veiculo_id"
                >
                  <autocomplete-tipo-veiculos
                    v-model="veiculoNovo.tipo_veiculo_id"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </validation-observer>
      <v-divider class="mt-4" />
      <v-card-actions>
        <v-row>
          <v-col cols="12" class="text-left">
            <btn-salvar
              :temPermissao="
                $store.getters['usuario/temPermissao']('editar.veiculos')
              "
              :carregando="carregandoSalvar"
              @click="$emit('salvar', veiculoNovo)"
            />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    validacaoFormulario: {
      type: Object,
      default: () => {
        return {};
      },
    },
    carregandoSalvar: {
      type: Boolean,
      default: false,
    },
   
    readonly: {
      type: Boolean,
      default: false,
    },
    veiculo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      veiculoNovo: {
        id: null,
        placa: null,
        descricao: null,
        tipo_veiculo_id: null,
      },
    };
  },

  watch: {
    validacaoFormulario(val) {
      if (!val) return;

      return this.$refs.observer.setErrors(val);
    },

    veiculo(veiculo) {
      this.veiculoNovo = veiculo;
    },
  },

  methods: {
    uppercase() {
      this.veiculoNovo.placa = this.veiculoNovo.placa.toUpperCase();
    },
  },
};
</script>

<style scoped>
.placa input {
  text-transform: uppercase;
}
</style>
